import { useCallback, useMemo, useSyncExternalStore } from 'react';
import { trackError } from '#api/errors';

/**
 * This is a somewhat-simplified and adjusted version of https://github.com/GabrielBB/react-use-session,
 * without support for JWTs. This has been updated to use `useSyncExternalStore()`: https://react.dev/reference/react/useSyncExternalStore
 *
 * If storage is not available, `null` is returned, and the setter and remover functions are no-ops.
 */
export function useBrowserStorage<T extends string | object>(
  key: string,
  storageType: 'session' | 'local' = 'session'
) {
  let storage: Storage | null;

  // In some contexts, just trying to use sessionStorage or localStorage throws a SecurityError
  try {
    if (storageType === 'local') {
      storage = localStorage;
    } else {
      storage = sessionStorage;
    }
  } catch (err) {
    storage = null;
  }

  const getSnapshot = useCallback(() => {
    try {
      return storage?.getItem(key);
    } catch (err) {
      if (storage) {
        // sometimes storage is not available in an environment, only track error if that's not the cause
        trackError(err);
      }
      return null;
    }
  }, [key, storage]);

  const subscribe = (callback: () => void) => {
    window.addEventListener('storage', callback);
    window.addEventListener('use-storage', callback);
    return () => {
      window.removeEventListener('storage', callback);
      window.removeEventListener('use-storage', callback);
    };
  };

  const rawState = useSyncExternalStore(subscribe, getSnapshot, () => null);

  const state = useMemo(() => {
    if (rawState != null) {
      // try parsing it out into an object, just in case
      try {
        return JSON.parse(rawState);
      } catch {
        return rawState;
      }
    }

    return null;
  }, [rawState]);

  function set(val: T) {
    const valToSet = typeof val === 'object' ? JSON.stringify(val) : String(val);

    try {
      storage?.setItem(key, valToSet);

      // We dispatch a custom event so `subscribe` is notified
      window.dispatchEvent(new Event('use-storage'));
    } catch (e) {
      if (storage) {
        // sometimes storage is not available in an environment, only track error if that's not the cause
        trackError(e);
      }
    }
  }

  function remove() {
    try {
      storage?.removeItem(key);
    } catch (err) {
      /* noop */
    }

    // We dispatch a custom event so `subscribe` is notified
    window.dispatchEvent(new Event('use-storage'));
  }

  return [state, set, remove] as const;
}
