import cn from 'classnames';
import { forwardRef } from 'react';
import { environmentVariables } from '#global/styles/environment-variables.json';
import { useMatchMedia } from '#hooks/useMatchMedia';
import styles from './PageHeader.module.css';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  preventTitleWrap?: boolean;
  wrapAtPx?: number;
};

export const PageHeader = forwardRef<HTMLDivElement, Props>(
  ({ title, subtitle, preventTitleWrap, children, className, wrapAtPx }, ref) => {
    const isExtraNarrow = useMatchMedia(`(max-width: ${wrapAtPx ?? environmentVariables['--size-breakpoint-xs']})`);

    return (
      <div ref={ref} className={styles.PageHeader}>
        <div className={cn(styles.PageHeader_main, className)}>
          <h1
            className={cn(
              styles.PageHeader_title,
              { [styles.PageHeader_title___preventWrap]: preventTitleWrap },
              'h2 mb-0'
            )}
          >
            {title}
          </h1>
          {isExtraNarrow && subtitle ?
            <div className={cn('mb-24', styles.PageHeader_subtitle)} key="subtitle">
              {subtitle}
            </div>
          : null}
          {children}
        </div>
        {!isExtraNarrow && subtitle ?
          <div className={styles.PageHeader_subtitle} key="subtitle">
            {subtitle}
          </div>
        : null}
      </div>
    );
  }
);
PageHeader.displayName = 'PageHeader';
