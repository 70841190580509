import type { Metadata } from '#dn-types/api';
import * as Cookies from '#util/cookies';
import {
  AuthenticationError,
  AuthorizationError,
  FetchError,
  InternalServerError,
  MissingResourceError,
  TimeoutError,
  ValidationError,
  type AuthErrorCode,
  type ErrorDetails,
} from './errors';
import { readNDJSONStream } from './ndjson.js';

export const HOST = import.meta.env.VITE_API_URL;

type ErrorBody = { errors: ErrorDetails[] };

/**
 * Pulls our auth token out of its cookie and add some default headers
 */
function buildHeaders(overrides?: HeadersInit) {
  const headers = new Headers(overrides);

  if (!headers.has('Content-Type')) {
    headers.set('Content-Type', 'application/json');
  }
  if (!headers.has('Accept')) {
    headers.set('Accept', 'application/json');
  }

  // Only set authorization header if one was not set in overrides
  if (!headers.has('Authorization')) {
    const userToken = Cookies.getItem(Cookies.USER_TOKEN_NAME);
    const authToken = Cookies.getItem(Cookies.AUTH_TOKEN_NAME);
    const oidcToken = Cookies.getItem(Cookies.OIDC_TOKEN_NAME);
    if (userToken) {
      headers.set('Authorization', `Bearer ${userToken} ${authToken}`.trim());
    } else if (oidcToken) {
      headers.set('Authorization', `Bearer ${oidcToken}`);
    }
  }

  // Filter out falsy values
  for (const [key, value] of headers.entries()) {
    if (!value) {
      headers.delete(key);
    }
  }

  return headers;
}

/**
 * convertErrors takes an api response that is not `ok`, and attempts to convert it
 * into a more specific error type which can be handled by our application code.
 */
export async function convertErrors(response: Response): Promise<never> {
  // Clone the response before we consume the json, so devtools can show it too
  const clonedResponse = response.clone();

  // Authentication error
  if (response.status === 401) {
    const body = (await clonedResponse.json().catch(() => {
      throw new FetchError(response);
    })) as ErrorBody;
    const error = body.errors[0];
    if (
      error?.code === 'ERR_UNAUTHORIZED' ||
      error?.code === 'ERR_2FA_REQUIRED' ||
      error?.code === 'ERR_2FA_RENEWAL_REQUIRED'
    ) {
      throw new AuthenticationError(error as ErrorDetails<AuthErrorCode>, response);
    }
    throw new FetchError(response);
  }

  // 403 Authorization error
  if (response.status === 403) {
    throw new AuthorizationError(response);
  }

  // 404 error
  if (response.status === 404) {
    throw new MissingResourceError(response);
  }

  if (response.status === 408) {
    const body = (await clonedResponse.json()) as ErrorBody;
    throw new TimeoutError(body.errors, response);
  }

  // 500 error
  if (response.status === 500) {
    const body = (await clonedResponse.json().catch(() => {
      throw new InternalServerError([], response);
    })) as ErrorBody;
    throw new InternalServerError(body.errors, response);
  }

  // Catch-all error
  throw new FetchError(response);
}

/**
 * Helper function to send POST requests against our api.
 */
export async function post<TReturn = unknown>(
  url: `/v${number}/${string}`,
  data = {},
  fetchInit?: RequestInit
): Promise<TReturn> {
  return modifyRemoteData('POST', url, data, fetchInit);
}

/**
 * Helper function to send PUT requests against our api.
 */
export async function put<TReturn = unknown>(
  url: `/v${number}/${string}`,
  data = {},
  fetchInit?: RequestInit
): Promise<TReturn> {
  return modifyRemoteData('PUT', url, data, fetchInit);
}

/**
 * Helper function to send DELETE requests against our api.
 */
export async function del<TReturn = unknown>(
  url: `/v${number}/${string}`,
  data = {},
  fetchInit?: RequestInit
): Promise<TReturn> {
  return modifyRemoteData('DELETE', url, data, fetchInit);
}

/**
 * Helper function to send GET requests against our api.
 */
export async function get<TReturn = unknown>(
  url: `/v${number}/${string}`,
  fetchInit: RequestInit = {}
): Promise<{ data: TReturn }> {
  const { headers: initHeaders, ...init } = fetchInit;
  const headers = buildHeaders(initHeaders);

  const response = await fetch(`${HOST}${url}`, {
    method: 'GET',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    ...init,
  });

  if (response.ok) {
    return response.json() as Promise<{ data: TReturn }>;
  }

  return await convertErrors(response);
}

/**
 * Helper function to send GET requests against our api to retrieve a list of items, including metadata.
 */
export async function getList<TReturn = unknown>(
  url: `/v${number}/${string}`,
  fetchInit: RequestInit = {}
): Promise<{ data: TReturn; metadata: Metadata }> {
  const { headers: initHeaders, ...init } = fetchInit;
  const headers = buildHeaders(initHeaders);

  const response = await fetch(`${HOST}${url}`, {
    method: 'GET',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    ...init,
  });

  if (response.ok) {
    return response.json() as Promise<{ data: TReturn; metadata: Metadata }>;
  }

  return await convertErrors(response);
}

/**
 * Helper function to send GET requests against our api, and return the value as a blob.
 * For example, we use this to generate a Blob for downloading CSV files from the audit log.
 */
export async function getBlob(url: `/v${number}/${string}`, fetchInit: RequestInit = {}): Promise<Blob> {
  const { headers: initHeaders, ...init } = fetchInit;
  const headers = buildHeaders(initHeaders);

  const response = await fetch(`${HOST}${url}`, {
    method: 'GET',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    ...init,
  });

  if (response.ok) {
    return response.blob();
  }

  return await convertErrors(response);
}

/**
 * This is an async generator function which yields lines of JSON as they are streamed from
 * an endpoint sending an NDJSON formatted response.
 */
export async function* getNDJSONStream(
  url: `/v${number}/${string}`,
  data = {},
  streamErrorHandler?: (error: unknown, line: string) => void,
  fetchInit: RequestInit = {}
) {
  const { headers: initHeaders, ...init } = fetchInit;
  const headers = buildHeaders(initHeaders);

  const response = await fetch(`${import.meta.env.VITE_API_URL}${url}`, {
    method: 'POST',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
    ...init,
  });

  if (response.ok && response.body) {
    for await (const event of readNDJSONStream(response.body, streamErrorHandler)) {
      yield event;
    }
  }

  // Form validation errors
  if (response.status === 400) {
    // Clone the response before we consume the json, so devtools can show it too
    const clonedResponse = response.clone();
    const body = (await clonedResponse.json()) as ErrorBody;
    // Lazy type assertion here, would be good to do better, but I couldn't figure it out yet
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!body.errors?.length) {
      // If this happens, we are in a situation we didn't expect
      throw new Error('No errors in validation error');
    }

    throw new ValidationError(body.errors, response);
  }

  return await convertErrors(response);
}

/**
 * The way we handle POST, PUT, and DELETE requests are very similar, so this DRYs them up.
 */
async function modifyRemoteData<TReturn = unknown>(
  method: 'POST' | 'PUT' | 'DELETE',
  url: `/v${number}/${string}`,
  data = {},
  fetchInit: RequestInit = {}
): Promise<TReturn> {
  const { headers: initHeaders, ...init } = fetchInit;
  const headers = buildHeaders(initHeaders);

  const response = await fetch(`${HOST}${url}`, {
    method,
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
    ...init,
  });

  if (response.ok) {
    return (response.json() as Promise<{ data: TReturn }>).then(({ data: responseData }) => responseData);
  }

  // Form validation errors
  if (response.status === 400) {
    // Clone the response before we consume the json, so devtools can show it too
    const clonedResponse = response.clone();
    const body = (await clonedResponse.json()) as ErrorBody;
    // Lazy type assertion here, would be good to do better, but I couldn't figure it out yet
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!body.errors?.length) {
      // If this happens, we are in a situation we didn't expect
      throw new Error('No errors in validation error');
    }

    throw new ValidationError(body.errors, response);
  }

  return await convertErrors(response);
}
