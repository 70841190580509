import { queryOptions, useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { makeMaxResourceQueryFn } from '#util/api';
import { networkQueryKeys, Networks } from '.';

const TEN_MINUTES = 10 * 60 * 1000;

export const makeNetworksQueryOptions = (staleTime = TEN_MINUTES) =>
  queryOptions({
    queryKey: networkQueryKeys.list(),
    queryFn: ({ signal }) => Networks.getNetworks({ init: { signal } }),
    staleTime,
  });

export const makeNetworkQueryOptions = (networkId: string) =>
  queryOptions({
    queryKey: networkQueryKeys.detail(networkId),
    queryFn: ({ signal }) => Networks.getNetwork(networkId, { signal }),
  });

export const maxNetworksQueryOptions = queryOptions({
  queryKey: networkQueryKeys.maxList,
  queryFn: makeMaxResourceQueryFn(Networks.getNetworks),
});

export function useNetworksList() {
  return useQuery(makeNetworksQueryOptions());
}

export function useSuspenseNetworksList() {
  return useSuspenseQuery(makeNetworksQueryOptions());
}

export function useSuspenseNetworkQuery(networkId: string) {
  return useSuspenseQuery(makeNetworkQueryOptions(networkId));
}

export function useDisableLighthousesAsRelaysMutation() {
  const queryClient = useQueryClient();
  const networkQueryOptions = makeNetworksQueryOptions();

  return useMutation({
    mutationFn: (id: string) => Networks.disableLighthousesAsRelays(id),
    onSuccess: (data) => {
      const cache = queryClient.getQueryData(networkQueryOptions.queryKey);
      // If we had a cache, let's find and update the changed network
      if (cache) {
        const newCache = cache.data.map((network) => {
          return network.id === data.id ? data : network;
        });
        queryClient.setQueryData(networkQueryOptions.queryKey, { data: newCache, metadata: cache.metadata });
      }

      // Even though we just set the data, let's treat it as invalid so it gets re-fetched again.
      void queryClient.invalidateQueries({ queryKey: networkQueryKeys.lists() });
    },
  });
}

/** {@link networkCountQueryOptions} returns the total number of networks in the account */
export const networkCountQueryOptions = queryOptions({
  queryKey: ['networkCount'],
  queryFn: async ({ signal }) => (await Networks.getNetworks({ pageSize: 1, init: { signal } })).metadata.totalCount,
});
