import * as Sentry from '@sentry/react';

/** {@link ErrorDetails} contains information about problems encountered during an API request. */
export type ErrorDetails<Code = string> = {
  /** A specific string constant indicating the error type. */
  code: Code;
  /** A fallback message to show the user if we fail to transform it based on the code. */
  message: string;
  /** Specifies the field that errored, in "dot.notation[0].for.subfields" */
  path?: string;
  /** Additional information specific to a particular type of error, such as a limit value */
  extra?: Record<string, unknown>;
};

/** An error that occurred during a fetch.  Contains the response. */
export class FetchError extends Error {
  response;
  requestId;
  errors: Array<ErrorDetails | RewordedErrorDetails>;

  constructor(response: Response) {
    super(response.statusText || String(response.status));
    this.name = 'FetchError';
    this.response = response;
    this.requestId = response.headers.get('x-request-id');
    this.errors = [];
  }
}

/** An error from form submission, containing an array of validation error objects */
export class ValidationError extends FetchError {
  reported;

  constructor(errors: Array<ErrorDetails | RewordedErrorDetails>, response: Response) {
    super(response);
    const fields = errors.map((error) => error.path ?? 'top-level');
    this.message = `Form validation error on field(s): ${fields.join(', ')}\n${JSON.stringify(errors, null, 2)}`;
    this.name = 'ValidationError';
    this.errors = errors;
    this.reported = false;
  }

  markReported() {
    this.reported = true;
  }
}

export class TimeoutError extends FetchError {
  constructor(errors: Array<ErrorDetails | RewordedErrorDetails>, response: Response) {
    super(response);
    this.message = `Server timeout: ${JSON.stringify(errors)}`;
    this.name = 'TimeoutError';
    this.errors = errors;
  }
}

export type AuthErrorCode = 'ERR_UNAUTHORIZED' | 'ERR_2FA_REQUIRED' | 'ERR_2FA_RENEWAL_REQUIRED';

/** The user is not fully authenticated */
export class AuthenticationError extends FetchError {
  code: AuthErrorCode;

  constructor(error: ErrorDetails<AuthErrorCode>, response: Response) {
    super(response);
    this.message = 'Not logged in';
    this.name = 'AuthenticationError';
    this.code = error.code;
  }
}

/** The user is not authorized */
export class AuthorizationError extends FetchError {
  constructor(response: Response) {
    super(response);
    this.message = 'Forbidden';
    this.name = 'AuthorizationError';
  }
}

export class MissingResourceError extends FetchError {
  constructor(response: Response) {
    super(response);
    this.message = 'Not Found';
    this.name = 'MissingResourceError';
  }
}

/** Whoops, something blew up on the server.  This is essentially a 500, Internal Server Error */
export class InternalServerError extends FetchError {
  constructor(errors: ErrorDetails[], response: Response) {
    super(response);
    this.name = 'InternalServerError';
    this.errors = errors;
  }
}

/**
 * Try to get field-level messages from an error and set those errors on form fields.
 *
 * @param error An error from the server, hopefully a ValidationError
 * @param setError a function that will call `useForm` of react-hook-form.  Should return true if the error
 *                 is successfully mapped, false otherwise.
 *
 * @returns boolean  Were any errors handled? Might want to throw a global error if not
 */
export function handleServerFormError(error: unknown, setError: (errMsg: string, path?: string) => boolean) {
  let anyHandled = false;
  if (error instanceof ValidationError) {
    error.errors.forEach((e) => {
      const handled = setError(e.message, e.path);
      if (handled) {
        anyHandled = true;
      } else if (e.path) {
        trackError(new Error(`Error on field "${e.path}" fell through: ${e.message}`));
      } else {
        trackError(new Error(`Error without path fell through: ${e.message}`));
      }
    });
  } else if (error instanceof Error) {
    raiseGlobalError(error.message);
    // I suppose raising a global error is a form of "handling" it...
    return true;
  }

  return anyHandled;
}

/**
 * When something general goes wrong, call this function with a message to show the user.
 *
 * TODO: Eventually this should be better, maybe a toast or a banner.
 */
function raiseGlobalError(message: string) {
  alert(message);
}

export function trackError(error: unknown) {
  let context:
    | {
        extra: { requestId: string | null; errors: Array<ErrorDetails | RewordedErrorDetails> };
      }
    | undefined;
  if (error instanceof FetchError) {
    context = { extra: { requestId: error.requestId, errors: error.errors } };
  }
  // eslint-disable-next-line import/namespace
  Sentry.captureException(error, context);
}

/**
 * {@link trackUnrewordedValidationError} filters out error details which have been reworded,
 * considering them to be expected validation errors.  Anything else is unexpected, and
 * needs to be surfaced to our error tracking system.
 *
 * @param error A {@link ValidationError} which might have un-reworded error details to track
 * @param errorTracker A callback with which to track the error
 * @returns true if the error was tracked, false if not
 */
export function trackUnrewordedValidationError(
  error: ValidationError,
  errorTracker: (ValidationError: ValidationError) => void
) {
  const errors: RewordedErrorDetails[] = error.errors;
  const unrewordedErrors = errors.filter((err) => !err.reworded);
  if (unrewordedErrors.length) {
    errorTracker(new ValidationError(unrewordedErrors, error.response));
    return true;
  }
  return false;
}

/**
 * {@link makeFriendlyMessage} is used to change the message of a validation error and mark the error as
 * reworded, so that it is not sent off to our error tracking service.
 *
 * @param errorDetails A single validation error which might need to be reworded.
 * @param message A new message to use instead of the server-provided message
 * @returns An {@link ErrorDetails} object with the new message and a `reworded: true` property.
 */
function makeFriendlyMessage(errorDetails: ErrorDetails, message: string): RewordedErrorDetails {
  return {
    ...errorDetails,
    reworded: true,
    message,
  };
}

type RewordedErrorDetails<Code = string> = ErrorDetails<Code> & { reworded?: boolean };
/**
 * {@link rewordValidationMessages} provides a means for the client to look at validation errors returned from the
 * api server and transform the message that will be shown to users, either to internationalize them (in the future),
 * or to re-word the message.  Any error message that is not reworded could be shown to the user and sent to
 * our error tracking service in case we should be handling it differently.
 *
 * @param err An error that might be a validation error or some other kind of error from the api server
 * @param reword A callback which is given an individual ErrorDetail, and should return a string message
 * for any errors that need to be reworded.  Any error details which do not have a string returned will be sent to
 * our error tracking service.
 * @param errorTracker A dependency-injection callback, will be called with unreworded errors, defaults
 * to our Sentry.io tracker.
 */
export function rewordValidationMessages(
  err: unknown,
  reword: (err: ErrorDetails) => string | undefined,
  errorTracker: (err: ValidationError) => void = trackError
): never {
  if (err instanceof ValidationError) {
    const errors: RewordedErrorDetails[] = err.errors.map((errorDetails) => {
      const newMessage = reword(errorDetails);
      if (newMessage) {
        return makeFriendlyMessage(errorDetails, newMessage);
      }
      return errorDetails;
    });

    const rewordedError = new ValidationError(errors, err.response);

    // If not all errors were reworded, send them to our error tracking service so we find out about them
    if (trackUnrewordedValidationError(rewordedError, errorTracker)) {
      rewordedError.markReported();
    }

    throw rewordedError;
  }

  // Throw original error if this wasn't a validation error
  throw err;
}
