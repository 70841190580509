import type { ResourceListOptions } from '#dn-types/api';
import type { Network } from '#dn-types/network';
import { rewordValidationMessages } from './errors';
import { del, get, getList, post, put } from './fetch';

export const DEFAULT_NETWORKS_PAGE_SIZE = 25;

// GET /networks
export type GetNetworksResult = Network[];
type GetNetworksVariables = ResourceListOptions;

export function getNetworks({ cursor, pageSize = DEFAULT_NETWORKS_PAGE_SIZE, init }: GetNetworksVariables = {}) {
  const params = new URLSearchParams({
    includeCounts: 'true',
    pageSize: pageSize.toString(),
  });

  if (cursor) {
    params.append('cursor', cursor);
  }

  return getList<GetNetworksResult>(`/v1/networks?${params.toString()}`, init).then((res) => {
    const page = res.metadata.page;
    // If the user paginates backwards, we may send a cursor which results in loading the "first" page of results
    // but with fewer results than the standard page size. Refetch the first page without a cursor to get a full result set.
    if (page?.start === 0 && page.count < pageSize && cursor) {
      params.delete('cursor');
      return getList<GetNetworksResult>(`/v1/networks?${params.toString()}`, init);
    }
    return res;
  });
}

// GET /networks/:id
export type GetNetworkResult = Network;
export function getNetwork(id: string, init?: RequestInit) {
  return get<GetNetworkResult>(`/v1/networks/${id}`, init);
}

// POST /networks
type AddNetworkVariables = {
  name: string;
  cidr: string;
  // TODO: Make mandatory when removing MULTIPLE_NETWORKS feature flag
  description?: string;
  lighthousesAsRelays?: boolean;
};
export type AddNetworkResult = Network;
export function addNetwork(formData: AddNetworkVariables) {
  return (
    post<AddNetworkResult>('/v1/networks', formData)
      // Convert from server error codes to more friendly strings, if possible
      .catch((error: unknown) => {
        rewordValidationMessages(error, (details) => {
          if (details.path === 'name' && details.code === 'ERR_DUPLICATE_VALUE') {
            return 'You already have a network with this name, try another';
          }
        });
      })
  );
}

// PUT /networks/:id
export type EditNetworkVariables = {
  id: string;
  values: {
    name: string;
    description: string;
    lighthousesAsRelays: boolean;
  };
};
export type EditNetworkResult = Network;

export function editNetwork({ id, values }: EditNetworkVariables) {
  return (
    put<EditNetworkResult>(`/v1/networks/${id}`, values)
      // Convert from server error codes to more friendly strings, if possible
      .catch((error: unknown) => {
        rewordValidationMessages(error, (details) => {
          if (details.path === 'name' && details.code === 'ERR_DUPLICATE_VALUE') {
            return 'You already have a network with this name, try another';
          }
        });
      })
  );
}

// DELETE /networks/:id
export type DeleteNetworkResult = Record<string, never>;
export function deleteNetwork(id: string, init?: RequestInit) {
  return (
    del<DeleteNetworkResult>(`/v1/networks/${id}`, init)
      // Convert from server error codes to more friendly strings, if possible
      .catch((err: unknown) => {
        rewordValidationMessages(err, (details) => {
          if (details.path === undefined && details.code === 'ERR_HAS_DEPENDENTS') {
            return 'This network contains hosts, relays, and/or lighthouses.  Please delete them and try again.';
          }
        });
      })
  );
}

export type DisableLighthousesAsRelaysResult = Network;
export function disableLighthousesAsRelays(networkId: string) {
  return post<DisableLighthousesAsRelaysResult>(`/v1/networks/${networkId}/disable-lighthouses-as-relays`);
}
