import { useCallback, useMemo, useSyncExternalStore } from 'react';

/**
 * {@link useMatchMedia} is a hook that uses window.matchMedia to determine whether
 * a query string matches or not.
 *
 * Note: events are not called consistently in chrome browsers:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1285532&q=matchmedia%20change&can=2
 *
 *
 * @ref https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 */
export function useMatchMedia(query: string) {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);

  const getSnapshot = useCallback(() => mediaQuery.matches, [mediaQuery]);
  const subscribe = useCallback(
    (callback: () => void) => {
      try {
        mediaQuery.addEventListener('change', callback);
        return () => mediaQuery.removeEventListener('change', callback);
      } catch (e) {
        // Fallback for safari < 14:
        // eslint-disable-next-line deprecation/deprecation
        mediaQuery.addListener(callback);
        // eslint-disable-next-line deprecation/deprecation
        return () => mediaQuery.removeListener(callback);
      }
    },
    [mediaQuery]
  );

  const matches = useSyncExternalStore(subscribe, getSnapshot);

  return matches;
}
