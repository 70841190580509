import { useEffect, useRef, useState } from 'react';
import { trackError } from '#api/errors';
import { CopyIcon } from '#assets/ReactSvg';
import { Tooltip } from '#components/atoms/tooltip/Tooltip';
import type { Network } from '#dn-types/network';
import { useTimeout } from '#hooks/useTimeout';
import { copyToClipboard } from '#util/copy-to-clipboard';
import styles from './NetworkCopyButton.module.css';

type Props = {
  network: Network;
};

/**
 * {@link NetworkCopyButton} shows a single network's CIDR range by default
 *
 * On hover it shows the ID
 *
 * On click it copies the ID to the clipboard
 */
export function NetworkCopyButton({ network: { cidr, id } }: Props) {
  const cidrSpanRef = useRef<HTMLSpanElement>(null);
  const idSpanRef = useRef<HTMLSpanElement>(null);
  const initialTooltip = 'Copy Network ID';
  const successTooltip = 'Copied!';

  const [tooltip, setTooltip] = useState(initialTooltip);
  const { start: resetTooltipAfterDelay, clear: clearTimeout } = useTimeout(() => {
    setTooltip(initialTooltip);
  }, 2000);

  // Reset the tooltip after 2 seconds
  useEffect(() => {
    if (tooltip === successTooltip) {
      resetTooltipAfterDelay();
    }
  }, [resetTooltipAfterDelay, successTooltip, tooltip]);

  return (
    <Tooltip
      content={tooltip}
      className={styles.NetworkCopyButton}
      onHidden={() => {
        setTooltip(initialTooltip);
        clearTimeout();
      }}
      placement="right"
      flip="bottom"
      render={
        <button
          type="button"
          onClick={() => {
            copyToClipboard(id)
              .then(() => {
                return setTooltip(successTooltip);
              })
              .catch((err: unknown) => {
                console.error('Could not copy');
                setTooltip('Unable to copy');
                trackError(err);
              });
          }}
        />
      }
    >
      <span className={styles.NetworkCopyButton_cidrContainer} ref={cidrSpanRef}>
        Network: {cidr}
      </span>
      {/** Animate enter/exit: https://stackoverflow.com/a/9334132 */}
      <span
        className={styles.NetworkCopyButton_idContainer}
        ref={idSpanRef}
        onAnimationStart={(e) => {
          if (e.animationName === styles['fade-in']) {
            idSpanRef.current?.classList.add(styles.NetworkCopyButton_idContainer___didFadeIn);
            cidrSpanRef.current?.setAttribute('aria-hidden', 'true');
          }
        }}
        onAnimationEnd={(e) => {
          if (e.animationName === styles['fade-out']) {
            idSpanRef.current?.classList.remove(styles.NetworkCopyButton_idContainer___didFadeIn);
            cidrSpanRef.current?.removeAttribute('aria-hidden');
          }
        }}
      >
        <span className="truncate">
          ID: <span title={id}>{id}</span>
        </span>
        <CopyIcon className={styles.NetworkCopyButton_copyIcon} aria-hidden="true" />
      </span>
    </Tooltip>
  );
}
